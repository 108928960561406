import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { extend } from 'lodash';
import { defaultAvatar } from '../../components/Common/constants';
// import { InvitationStatuses, InviteModel } from 'apps/people/models/InviteModel';
// import { OrganizationModel } from 'apps/organization/models/OrganizationModel';

import httpService from "../../utils/httpService";
const initialState = {
    info: { permissions: [], loading: false } as any,
    projects: [] as any[],
    // organizations: [] as OrganizationModel[],
    // invites: [] as InviteModel[],
    loading: false,
    language: ""
}

export const checkLoginStatus = createAsyncThunk('user/current', async (redirecturl) => {
    const response = await httpService.get(`/login?redirecturl=${redirecturl}`)
    return response.data
})

export const register = createAsyncThunk('user/register', async ({ user, validationCode }: { user: any, validationCode: string }, { rejectWithValue }) => {
    try {
        const response = await httpService.post(`/register`, user)
        return response.data
    } catch (err: any) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        return rejectWithValue(error.response.data)
    }
})
export const confirmEmail = createAsyncThunk('user/emailConfirmition', async (confirmatioCode: any) => {
    const response = await httpService.post(`/confirm/email?code=${confirmatioCode}`)
    return response.data
})
export const addUser = createAsyncThunk('user/add', async (user: any) => {
    const response = await httpService.post('/users', user)
    return response.data
})

export const login = createAsyncThunk('user/login', async (loginInfo: any, { rejectWithValue }) => {
    try {
        const response = await httpService.post(`/login?redirecturl=${loginInfo.redirecturl}`, loginInfo)
        return response.data
    } catch (err: any) {
        let error = err // cast the error for access
        if (!error.response) {
            throw err
        }
        // We got validation errors, let's return those so we can reference in our component and set form errors
        return rejectWithValue(error.response.data)
    }
})

export const sendPasswordResetOTP = createAsyncThunk('user/sendPasswordResetOTP', async (data: any) => {
    const response = await httpService.post('/authentication/sendPasswordResetOTP', data)
    return response.data
})
export const logout = createAsyncThunk('user/logout', async () => {
    const response = await httpService.post('/logout')
    return response.data
})


export const updateMe = createAsyncThunk('me/update', async (meInfo) => {
    await httpService.put('/me', meInfo)
    return meInfo
})


export const setPassword = createAsyncThunk('me/setpassword', async (dataToRequest) => {
    await httpService.put('/me/setPassword', dataToRequest)
    return { hasPassword: true }
})

export const uploadMyAvatar = createAsyncThunk('me/uploadAvatar', async (avatar: any) => {
    const formData = new FormData();
    formData.append('avatar', avatar);

    const response = await httpService.post('/me/picture', formData)
    return response.data
})

export const removeMyAvatar = createAsyncThunk('me/uploadAvatar', async () => {
    const response = await httpService.delete('/me/picture')
    return response.data
})

export const slackConfirmLogin = createAsyncThunk('slack/oauth', async ({ state, code }: { state: String, code: String }) => {
    const response = await httpService.get(`/slack/oauth?state=${state}&code=${code}`);
    return response.data
})

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetUser(state) {
            // state.invites = [];
            // state.organizations = [];
            state.info = { ...initialState.info };
            state.loading = false
        },
        setDefaultLanguage(state, action) {
            state.language = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(checkLoginStatus.pending, (state, action) => {
                state.info.loading = true;
            }).addCase(checkLoginStatus.rejected, (state, action) => {
                state.info.loading = false;
            })
            .addCase(checkLoginStatus.fulfilled, (state, action) => {
                state.info = action.payload;
                // state.language = action.payload.language;
            })
            // .addCase(loadMeOrganizations.fulfilled, (state, action) => {
            //     // state.organizations = action.payload
            // })
            .addCase(logout.fulfilled, (state) => {
                state.info = initialState.info;
            })
            // .addCase(loadMeProjects.fulfilled, (state, action) => {
            //     state.projects = action.payload;
            // }).addCase(loadMeInvites.fulfilled, (state, action) => {
            //     // state.invites = action.payload;
            // }).addCase(confirmInvite.fulfilled, (state, action) => {
            //     // const newInvites = state.invites;
            //     // const confirmedInvite = newInvites.find(a => a.validationCode === action.payload.validationCode);
            //     // if (!confirmedInvite) {
            //     //     return;
            //     // }
            //     // confirmedInvite.status = InvitationStatuses.Confirmed;

            // }).addCase(rejectInvite.fulfilled, (state, action) => {
            //     // const newInvites = state.invites;
            //     // const rejectedInvite = newInvites.find(a => a.validationCode === action.payload.validationCode);
            //     // if (!rejectedInvite) {
            //     //     return;
            //     // }
            //     // rejectedInvite.status = InvitationStatuses.Rejected;

            // })
            .addCase(updateMe.fulfilled, (state, action: any) => {
                delete action.payload.password;
                delete action.payload.currentPassword;
                extend(state.info, action.payload);
                if (action.payload.language)
                    state.language = action.payload.language;
            }).addCase(setPassword.fulfilled, (state, action: any) => {
                extend(state.info, action.payload);
            }).addCase(removeMyAvatar.fulfilled, (state) => {
                extend(state.info, { photo: defaultAvatar.defaultAvatars.USER });
            })
            .addMatcher(isAnyOf(login.pending, logout.pending, checkLoginStatus.pending,
                // loadMeProjects.pending,
                addUser.pending,
                updateMe.pending,
                setPassword.pending,
                register.pending),
                (state) => { state.loading = true })
            .addMatcher(isAnyOf(login.rejected, logout.rejected,
                checkLoginStatus.rejected,
                // loadMeProjects.rejected,
                addUser.rejected, updateMe.rejected,
                setPassword.rejected, register.rejected,
                login.fulfilled, logout.fulfilled, checkLoginStatus.fulfilled,
                // loadMeProjects.fulfilled,
                addUser.fulfilled, updateMe.fulfilled, setPassword.fulfilled, register.fulfilled)
                , (state) => { state.loading = false })

    }
})

export const { resetUser, setDefaultLanguage } = userSlice.actions;
export default userSlice.reducer