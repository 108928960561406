// import { APPS } from "components/Common/constants";

import { checkLoginStatus } from "store/actions";

// import {
//     getAllOKRSavedView, loadAllTeams, loadCurrentOrganization, loadCurrentUser, loadMeInvites, loadMembers, loadMeOrganizations, loadMeProjects,
//     loadMessageStatistic, loadProjects, loadRoles, resetMembers, resetOrganization, resetProjects, resetReports,
//     resetUser
// } from "store/actions";
// import store from 'store/store';
// import { getAppsByPermissions } from "utils/useAppHelper";

export const loadInitData = (dispatch) => {
    // console.log(window.location.pathname);
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    // console.log(params.redirecturl)
    const redirecturl = params.redirecturl //"http://fara.team.local:3000";
    dispatch(checkLoginStatus(redirecturl)).then((status) => {
        if (!status.payload || status.payload.length === 0) {
            if (![
                // "/init",
                "/account/accountSetting",
                '/account/changePassword',
                "/logout",
                "/login",
                "/signup",
            ].includes(window.location.pathname)) {
                window.location = "/login"
            }
        } else {
            if (![
                "/logout"
            ].includes(window.location.pathname)) {
                window.location = status.payload.redirectUrlWithTempToken
            }
        }
        //     dispatch(loadMeOrganizations()).then(organizations => {
        //         if (organizations.payload && organizations.payload.length > 0) {
        //             dispatch(loadCurrentOrganization()).then(() => {

        //                 dispatch(loadMeInvites());
        //                 dispatch(loadMessageStatistic());


        //                 dispatch(loadMembers());
        //                 dispatch(loadAllTeams());
        //                 dispatch(loadRoles());


        //             })
        //         } else {
        //             if (![
        //                 "/init",
        //                 "/account/accountSetting",
        //                 '/account/changePassword',
        //                 "/logout",
        //                 "/login"
        //             ].includes(window.location.pathname)) {
        //                 window.location = "/init"
        //             }
        //         }
        //     })
    });


}

export const removeInitData = async (dispatch) => {
    // await dispatch(resetProjects());
    // await dispatch(resetMembers());
    // await dispatch(resetReports());
    // await dispatch(resetOrganization());
    // await dispatch(resetUser());
}

