import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { APPS } from '../components/Common/constants';
import { isAuthenticate } from 'store/selectors';
import { useAppSelector } from 'store/store';
import { useAppHelper } from 'utils/useAppHelper';
// import { AccessDenied } from 'components/Common/PermissionComponent';
import PageLoader from 'components/Common/PageLoader';

export interface PrivateRouteParams extends RouteProps {
    permissions?: string[]
}

const PrivateRoute: React.FC<PrivateRouteParams> = ({ children, permissions, component, ...props }) => {
    const isAuth = useAppSelector(state => isAuthenticate(state));

    let cmp = component;

    if (!isAuth) {
        return <Route path={props.path} exact={props.exact} component={() => <Redirect to='/login' />} />;
    }
    return <Route {...props} component={cmp} />;
}
export default PrivateRoute;

export const DefaultRoot = () => {
    const { userApps, loading }: { userApps: any[], loading: boolean } = useAppHelper();
    const isAuth = useAppSelector(state => isAuthenticate(state));
    const currentOrganizationId = useAppSelector(state => state.user.info.currentOrganization_id)
    if (!isAuth) {
        return <Redirect to='/login' />;
    }
    if (loading) {
        return <Route path='*' exact={true} component={() => <PageLoader />} />
    }
    if (!currentOrganizationId) {
        return <Redirect path="/" to={'/init'} />
    }

    if (userApps.length > 0) {
        const userAppsWithoutOrganization = userApps.filter((a: any) => a.serverName !== APPS.Organization.serverName);
        if (userAppsWithoutOrganization.length === 0) {
            return <Redirect path="/" to={'/access-denied'} />
        }
        return <Redirect path="/" to={"/" + userAppsWithoutOrganization[0].frontName} />
    }
    // return <Route path='*' exact={true} component={() => <AccessDenied showLinks={true} />} />

}