import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { APPS } from "../components/Common/constants";
import { changeSetting } from "../store/actions";
const apps = Object.entries(APPS).map(a => a[1]);
export const getAppsByPermissions = (permissions) => {
    const appsByPermission = permissions.map(a => a.split("-")[0]);
    const userApps = apps.filter(a => appsByPermission.some(b => b.toLowerCase() === a.serverName.toLowerCase()));
    if (userApps.some(a => a.frontName.toLowerCase() === APPS.Organization.frontName)) {
        userApps.push(APPS.People);
    }
    if (userApps.length === 0) {
        // userApps.push(APPS.Init);
    }
    return userApps;
}

export const useAppHelper = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const userPermissions = useSelector(state => state.user.info.permissions);
    const appsMenuIsHidden = useSelector(state => state.settings.appsMenuIsHidden);

    const [currentApp, setCurrentApp] = useState();
    const [userApps, setUserApps] = useState([]);
    const [userAppsLoaded, setUserAppsLoaded] = useState(false);
    const [currentAppLoaded, setCurrentAppLoaded] = useState(false);

    const appsJson = JSON.stringify(apps);
    const userAppsJson = JSON.stringify(userApps);
    const userPermissionsJson = JSON.stringify(userPermissions);

    // set current app
    useEffect(() => {
        const getAppName = (path) => {
            const userApps = JSON.parse(appsJson);
            if (userApps.length === 0) {
                return "";
            };
            const currentApp = userApps.find(app => path.split('/')[1] === app.frontName) || userApps[0];
            return currentApp;
        }
        const appName = getAppName(location.pathname)
        setCurrentApp(appName);
        setCurrentAppLoaded(true);
    }, [appsJson, location.pathname]);

    // set user apps from permissions
    useEffect(() => {
        const userPermissions = JSON.parse(userPermissionsJson);
        const userApps = getAppsByPermissions(userPermissions);
        setUserApps(userApps);
        setUserAppsLoaded(true);
    }, [userPermissionsJson, appsJson]);

    //dispatch change setting to hide/show menu
    useEffect(() => {
        const hideAppsMenu = JSON.parse(userAppsJson).filter(a => a.serverName.toLowerCase() !== APPS.Organization.serverName && a.serverName.toLowerCase() !== APPS.People.serverName).length < 2;
        if (hideAppsMenu !== appsMenuIsHidden) {
            dispatch(changeSetting({ name: 'appsMenuIsHidden', value: hideAppsMenu }));
        }
    }, [userAppsJson, appsMenuIsHidden, dispatch]);

    const hasUserThisApp = (appName) => {
        let appNameToCompare = appName;
        return userApps.some(a => a.frontName === appNameToCompare);
    }

    return { currentApp: currentApp ? currentApp : {}, userApps, hasUserThisApp, loading: !currentAppLoaded || !userAppsLoaded }
}