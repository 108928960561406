import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import PrivateRoute from 'routes/PrivateRoute';
import { getSignupEnabled, isSystemModePublic } from 'store/selectors';
const Login = lazy(() => import("apps/signupLogin/src/Login"));
const Register = lazy(() => import("apps/signupLogin/src/Register/Signup"));
const Logout = lazy(() => import("apps/signupLogin/src/Logout"));
const InitForm = lazy(() =>
    import("apps/signupLogin/src/InitForm")
);
const AccessDenied = lazy(() =>
    import("apps/signupLogin/src/AccessDeniedPage")
);
const SuccessRegister = lazy(() =>
    import("apps/signupLogin/src/Register/SuccessRegister")
);
// const InviteConfirmation = lazy(() =>
//     import("apps/signupLogin/src/Register/InviteConfirmation")
// );
const EmailConfirmation = lazy(() =>
    import("apps/signupLogin/src/Register/EmailConfirmation")
);
const SSOLoginSuccess = lazy(() =>
    import("apps/signupLogin/src/SSO/SSOLoginSuccess")
);
const SSOLoginSuccessDesktopApp = lazy(() =>
    import("apps/signupLogin/src/SSO/SSOLoginSuccessDesktopApp")
);
const OneTapLoginSuccess = lazy(() =>
    import("apps/signupLogin/src/SSO/OneTapLoginSuccess")
);
const SSOLoginError = lazy(() =>
    import("apps/signupLogin/src/SSO/SSOLoginError")
);

//slack
const SlackLoginSuccess = lazy(() =>
    import("apps/signupLogin/src/SSO/Slack/SlackLoginSuccess")
);
const SlackLoginRedirect = lazy(() =>
    import("apps/signupLogin/src/SSO/Slack/SlackLoginRedirect")
);

export const SignupLoginRoutesList = [
    "",
    "/login",
    "/signup",
    "/signup_9879",
    "/signup/:validationCode?",
    "/signup_9879/:validationCode?",

    "/logout",
    "/welcome",
    "/emailConfirmation/:activationCode?",
    // "/inviteConfirmation/:validationCode?",
    "/sso/login/success",
    "/sso/login/desktop/success",
    "/sso/login/error",
    "/sso/onetap/success",


]
const waitFor = (Tag) => (props) => <Tag {...props} />;

const SignupLoginRoutes = () => {
    const isSystemPublic = useSelector(isSystemModePublic);
    const isSignupEnabled = useSelector(getSignupEnabled);

    return (
        <>
            <Switch>
                <Route
                    exact
                    path="/logout"
                    component={waitFor(Logout)}
                />
                <Route
                    exact
                    path="/login"
                    component={waitFor(Login)}
                />
                {isSignupEnabled &&
                    <Route
                        exact
                        path="/signup/:validationCode?"
                        component={waitFor(Register)}
                    />
                }
                <Route
                    exact
                    path="/signup_9879/:validationCode?"
                    component={waitFor(Register)}
                />

                <Route
                    exact
                    path="/welcome"
                    component={waitFor(SuccessRegister)}
                />
                <Route
                    exact
                    path="/emailConfirmation/:activationCode?"
                    component={waitFor(EmailConfirmation)}
                />
                {/* <Route
                    exact
                    path="/inviteConfirmation/:validationCode?"
                    component={waitFor(InviteConfirmation)}
                /> */}
                <Route
                    exact
                    path="/sso/login/success"
                    component={waitFor(SSOLoginSuccess)}
                />
                <Route
                    exact
                    path="/sso/login/desktop/success"
                    component={waitFor(SSOLoginSuccessDesktopApp)}
                />
                <Route
                    exact
                    path="/sso/login/error"
                    component={waitFor(SSOLoginError)}
                />
                <Route
                    exact
                    path="/sso/onetap/success"
                    component={waitFor(OneTapLoginSuccess)}
                />

                <Redirect to="/login" />
            </Switch>
        </>
    )
}

export default SignupLoginRoutes

export const SignupLoginRoutesWithoutSidebarList = [
    "/init",
    "/access-denied",
    "/slack/oauth"
]

export const SignupLoginRoutesWithoutSidebar = () => {
    const isSystemPublic = useSelector(isSystemModePublic);
    return (
        <>
            <Switch>
                {isSystemPublic &&
                    <PrivateRoute
                        permissions={[]}
                        exact
                        path="/init"
                        component={waitFor(InitForm)}
                    />
                }
                <PrivateRoute
                    permissions={[]}
                    exact
                    path="/access-denied"
                    component={waitFor(AccessDenied)}
                />
                {/* slack routes */}
                <PrivateRoute
                    exact
                    path="/sso/login/slack/redirect"
                    component={waitFor(SlackLoginRedirect)}
                />
                <PrivateRoute
                    exact
                    path="/slack/oauth"
                    component={waitFor(SlackLoginSuccess)}
                />
            </Switch>
        </>
    )
}