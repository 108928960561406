import React, { useEffect } from 'react';

// import Header from './Header'
// import Sidebar from './Sidebar'
import PageLoader from 'components/Common/PageLoader';
import Toastify from 'components/Common/Toastify';
import { Suspense } from 'react';
import { useHistory } from 'react-router';
import { isAuthenticate } from 'store/selectors';
import { useAppSelector } from 'store/store';
import "react-toastify/dist/ReactToastify.css";

const Base = (props: { children: any, hideSidebar: boolean }) => {
    const isAuth = useAppSelector(isAuthenticate);
    const loading = useAppSelector(state => state.user.info.loading);
    const history = useHistory();
    useEffect(() => {
        // if (!loading && !isAuth) {
        //     history.push("/login?returnUrl=" + history.location.pathname + encodeURIComponent(history.location.search));
        // }
    }, [history, isAuth, loading])
    if (loading || !isAuth) {
        return <PageLoader></PageLoader>
    }
    return (
        <div className="wrapper scroll-hidden">

            <Toastify />
            <Suspense fallback={<PageLoader></PageLoader>}>
                {/* <Header /> */}
                <div className={'flex-grow-1 flex-row d-flex scroll-hidden'}>
                    {/* {!props.hideSidebar && <Sidebar />} */}
                    <section className="section-container flex-grow-1">
                        {/* <div className="sidebar-backdrop sidebar-backdrop-fade show">
                        </div> */}
                        {/* <SidebarBackDrop /> */}
                        {props.children}
                    </section>
                </div>


            </Suspense>



            {/* <Footer /> */}
        </div>
    )

}

export default Base;

export const BaseWithoutSidebar = (props: { children: any }) => {
    return <Base hideSidebar={true} children={props.children} />
}