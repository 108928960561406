import 'core-js/es6/array';
import 'core-js/es6/map';
import 'core-js/es6/object';
import 'core-js/es6/promise';
import 'core-js/es6/set';
import 'core-js/es6/string';
import 'core-js/es7/array';
import 'core-js/es7/object';
import 'raf/polyfill';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import PageLoader from './components/Common/PageLoader';
import store from './store/store';


import { ThirdPartyIntegrations } from 'components/Layout/ThirdPartyIntegrations';
import { ConfigProvider } from 'react-avatar';
import { loadInitData } from './services/init';
import { changeProductSetting, changeSetting, changeTheme, loadServerConfig } from './store/actions';
import httpService from './utils/httpService';

store.dispatch(changeTheme('themes/theme-a.css'));


fetch('/config.json'
    , {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }
)
    .then(function (response) {
        return response.json();
    })
    .then(function (configJson) {
        console.log(configJson);
        httpService.initUrl(configJson.apiUrl, configJson.apiPort);
        store.dispatch(changeSetting({ name: 'languages', value: configJson.languages }));
        store.dispatch(changeSetting({ name: 'calendarTypes', value: configJson.calendarTypes }));
        // const state = store.getState();
        // if (state.user && state.user.info && state.user.info.id) {
        loadInitData(store.dispatch);
        // }

        store.dispatch(loadServerConfig());

        httpService.get("/branding").then(branding => {
            store.dispatch(changeProductSetting(branding.data.product))
        });
        if (configJson.logging?.sentry?.enable === 'true') {
            Sentry.init({
                dsn: configJson.logging?.sentry?.dsn,
                integrations: [new BrowserTracing()],
                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                // We recommend adjusting this value in production
                tracesSampleRate: configJson.logging?.sentry?.tracesSampleRate,
            });
        }
    });



ReactDOM.render(

    <Provider store={store}>
        <ThirdPartyIntegrations />
        <Suspense fallback={<PageLoader></PageLoader>}>
            <ConfigProvider colors={[
                'var(--avatar-color1)',
                'var(--avatar-color2)',
                'var(--avatar-color3)',
                'var(--avatar-color4)',
                'var(--avatar-color5)',
                'var(--avatar-color6)',
            ]}>
                <App />
            </ConfigProvider>
        </Suspense>
    </Provider>

    ,
    document.getElementById('app')
);
